$epartners-tiny-black: -2px -2px 200px 47px;
$epartners-small-black: -2px -53px 250px 59px;
$epartners-small: -2px -117px 250px 59px;
$epartners-black: -2px -180px 315px 75px;
$epartners: -2px -259px 425px 100px;

@mixin sprite-width($sprite) {
    width: nth($sprite, 3);
}

@mixin sprite-height($sprite) {
    height: nth($sprite, 4);
}

@function sprite-width($sprite) {
    @return nth($sprite, 3);
}

@function sprite-height($sprite) {
    @return nth($sprite, 4);
}

@mixin sprite-position($sprite) {
    $sprite-offset-x: nth($sprite, 1);
    $sprite-offset-y: nth($sprite, 2);
    background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite($sprite) {
    @include sprite-position($sprite);
    background-repeat: no-repeat;
    overflow: hidden;
    display: block;
    @include sprite-width($sprite);
    @include sprite-height($sprite);

    background-image: url("../assets/images/sprite.png");

    @media (min--moz-device-pixel-ratio: 1.2), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.2), (min-device-pixel-ratio: 1.2), (min-resolution: 1.2dppx) {
        background-image: url("../assets/images/sprite@2x.png");
        background-size: 429px 361px;
    }
}

.epartners-tiny-black { @include sprite($epartners-tiny-black) }
.epartners-small-black { @include sprite($epartners-small-black) }
.epartners-small { @include sprite($epartners-small) }
.epartners-black { @include sprite($epartners-black) }
.epartners { @include sprite($epartners) }
