/* Archiv
##################################################################################################################### */

.filter {
    margin: 3rem 0;
    padding: 1.5rem 0 1.25rem;
    border-top: 1px solid $ep-base-color;
    border-bottom: 1px solid $ep-base-color;

    h2, h3 {
        margin: 0 0 .75rem;
    }

    .tag {
        display: inline-block;
        margin: 0 .25rem .5rem 0;
        padding: .2rem .5rem;
        text-decoration: none;
        @include transition(all .12s ease-in-out);

        .icon-cancel {
            display: none;
            margin-left: .25rem;
            font-size: .7rem;

            &:before {
                position: relative;
                top: -2px;
                margin: 0 0 0 2px;
                padding: 0;
            }
        }

        &:hover {
            background: $gray-240;
        }

        &.active {
            color: $white;
            background: $ep-base-color;

            .icon-cancel {
                display: inline-block;
            }

            &:hover {
                background: darken($ep-base-color, 5%);
            }
        }
    }
}