/* Helpers
##################################################################################################################### */

// Space helpers
.nospace {
    margin-bottom: 0 !important;
}

.halfspace {
    margin-bottom: .5em !important;
}

.space-1 {
    margin-bottom: 1em !important;
}

.space-2 {
    margin-bottom: 2em !important;
}

.space-3 {
    margin-bottom: 3em !important;
}

.space-4 {
    margin-bottom: 4em !important;
}

// Text helpers
.tx-base-color {
    color: $ep-base-color;
}

.tx-gray {
    color: $gray-50;
}

.tx-italic {
    font-style: italic;
}

.tx-small {
    font-size: .85rem;
    line-height: 1.25em;
}

// Icon padding
.icon-pos-left {
    margin-right: .5em;
    margin-left: 0;
}

.icon-pos-right {
    margin-right: 0;
    margin-left: .5em;
}


// Icon positions
.icon-absolute-left {
    position: relative;
    display: inline-block;
    padding-left: 1.5em;

    span {
        position: absolute;
        top: 0;
        left: -.25em;
    }
}