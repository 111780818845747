.home-slider {
    z-index: 0;

    .img-init {
        display: none;
    }

    .cycle-slide {
        width: inherit;
        height: inherit;
        display: block;
    }
}