@charset "UTF-8";
/* Base
##################################################################################################################### */
meta.foundation-version {
  font-family: "/5.5.1/"; }

meta.foundation-mq-small {
  font-family: "/only screen/";
  width: 0em; }

meta.foundation-mq-small-only {
  font-family: "/only screen and (max-width: 46em)/";
  width: 0em; }

meta.foundation-mq-medium {
  font-family: "/only screen and (min-width:46.063em)/";
  width: 46.063em; }

meta.foundation-mq-medium-only {
  font-family: "/only screen and (min-width:46.063em) and (max-width:95em)/";
  width: 46.063em; }

meta.foundation-mq-large {
  font-family: "/only screen and (min-width:95.063em)/";
  width: 95.063em; }

meta.foundation-mq-large-only {
  font-family: "/only screen and (min-width:95.063em) and (max-width:105em)/";
  width: 95.063em; }

meta.foundation-mq-xlarge {
  font-family: "/only screen and (min-width:105.063em)/";
  width: 105.063em; }

meta.foundation-mq-xlarge-only {
  font-family: "/only screen and (min-width:105.063em) and (max-width:120em)/";
  width: 105.063em; }

meta.foundation-mq-xxlarge {
  font-family: "/only screen and (min-width:120.063em)/";
  width: 120.063em; }

meta.foundation-data-attribute-namespace {
  font-family: false; }

html, body {
  height: 100%; }

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html, body {
  font-size: 100%; }

body {
  background: #fff;
  color: #222;
  padding: 0;
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  position: relative;
  cursor: auto; }

a:hover {
  cursor: pointer; }

img {
  max-width: 100%;
  height: auto; }

img {
  -ms-interpolation-mode: bicubic; }

#map_canvas img, #map_canvas embed, #map_canvas object, .map_canvas img, .map_canvas embed, .map_canvas object {
  max-width: none !important; }

.left {
  float: left !important; }

.right {
  float: right !important; }

.clearfix:before, .clearfix:after {
  content: " ";
  display: table; }
.clearfix:after {
  clear: both; }

.hide {
  display: none; }

.invisible {
  visibility: hidden; }

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle; }

textarea {
  height: auto;
  min-height: 50px; }

select {
  width: 100%; }

.row {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  max-width: 1350px; }
  .row:before, .row:after {
    content: " ";
    display: table; }
  .row:after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-left: 0;
    padding-right: 0; }
  .row.collapse .row {
    margin-left: 0;
    margin-right: 0; }
  .row .row {
    width: auto;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 0;
    margin-bottom: 0;
    max-width: none; }
    .row .row:before, .row .row:after {
      content: " ";
      display: table; }
    .row .row:after {
      clear: both; }
    .row .row.collapse {
      width: auto;
      margin: 0;
      max-width: none; }
      .row .row.collapse:before, .row .row.collapse:after {
        content: " ";
        display: table; }
      .row .row.collapse:after {
        clear: both; }

.column, .columns {
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  float: left; }

[class*="column"] + [class*="column"]:last-child {
  float: right; }

[class*="column"] + [class*="column"].end {
  float: left; }

@media only screen {
  .small-push-0 {
    position: relative;
    left: 0%;
    right: auto; }
  .small-pull-0 {
    position: relative;
    right: 0%;
    left: auto; }
  .small-push-1 {
    position: relative;
    left: 8.33333%;
    right: auto; }
  .small-pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto; }
  .small-push-2 {
    position: relative;
    left: 16.66667%;
    right: auto; }
  .small-pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto; }
  .small-push-3 {
    position: relative;
    left: 25%;
    right: auto; }
  .small-pull-3 {
    position: relative;
    right: 25%;
    left: auto; }
  .small-push-4 {
    position: relative;
    left: 33.33333%;
    right: auto; }
  .small-pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto; }
  .small-push-5 {
    position: relative;
    left: 41.66667%;
    right: auto; }
  .small-pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto; }
  .small-push-6 {
    position: relative;
    left: 50%;
    right: auto; }
  .small-pull-6 {
    position: relative;
    right: 50%;
    left: auto; }
  .small-push-7 {
    position: relative;
    left: 58.33333%;
    right: auto; }
  .small-pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto; }
  .small-push-8 {
    position: relative;
    left: 66.66667%;
    right: auto; }
  .small-pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto; }
  .small-push-9 {
    position: relative;
    left: 75%;
    right: auto; }
  .small-pull-9 {
    position: relative;
    right: 75%;
    left: auto; }
  .small-push-10 {
    position: relative;
    left: 83.33333%;
    right: auto; }
  .small-pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto; }
  .small-push-11 {
    position: relative;
    left: 91.66667%;
    right: auto; }
  .small-pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto; }
  .column, .columns {
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
    float: left; }
  .small-1 {
    width: 8.33333%; }
  .small-2 {
    width: 16.66667%; }
  .small-3 {
    width: 25%; }
  .small-4 {
    width: 33.33333%; }
  .small-5 {
    width: 41.66667%; }
  .small-6 {
    width: 50%; }
  .small-7 {
    width: 58.33333%; }
  .small-8 {
    width: 66.66667%; }
  .small-9 {
    width: 75%; }
  .small-10 {
    width: 83.33333%; }
  .small-11 {
    width: 91.66667%; }
  .small-12 {
    width: 100%; }
  .small-offset-0 {
    margin-left: 0% !important; }
  .small-offset-1 {
    margin-left: 8.33333% !important; }
  .small-offset-2 {
    margin-left: 16.66667% !important; }
  .small-offset-3 {
    margin-left: 25% !important; }
  .small-offset-4 {
    margin-left: 33.33333% !important; }
  .small-offset-5 {
    margin-left: 41.66667% !important; }
  .small-offset-6 {
    margin-left: 50% !important; }
  .small-offset-7 {
    margin-left: 58.33333% !important; }
  .small-offset-8 {
    margin-left: 66.66667% !important; }
  .small-offset-9 {
    margin-left: 75% !important; }
  .small-offset-10 {
    margin-left: 83.33333% !important; }
  .small-offset-11 {
    margin-left: 91.66667% !important; }
  .small-reset-order {
    margin-left: 0;
    margin-right: 0;
    left: auto;
    right: auto;
    float: left; }
  .column.small-centered, .columns.small-centered {
    margin-left: auto;
    margin-right: auto;
    float: none; }
  .column.small-uncentered, .columns.small-uncentered {
    margin-left: 0;
    margin-right: 0;
    float: left; }
  .column.small-centered:last-child, .columns.small-centered:last-child {
    float: none; }
  .column.small-uncentered:last-child, .columns.small-uncentered:last-child {
    float: left; }
  .column.small-uncentered.opposite, .columns.small-uncentered.opposite {
    float: right; }
  .row.small-collapse > .column, .row.small-collapse > .columns {
    padding-left: 0;
    padding-right: 0; }
  .row.small-collapse .row {
    margin-left: 0;
    margin-right: 0; }
  .row.small-uncollapse > .column, .row.small-uncollapse > .columns {
    padding-left: 20px;
    padding-right: 20px;
    float: left; } }

@media only screen and (min-width:46.063em) {
  .medium-push-0 {
    position: relative;
    left: 0%;
    right: auto; }
  .medium-pull-0 {
    position: relative;
    right: 0%;
    left: auto; }
  .medium-push-1 {
    position: relative;
    left: 8.33333%;
    right: auto; }
  .medium-pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto; }
  .medium-push-2 {
    position: relative;
    left: 16.66667%;
    right: auto; }
  .medium-pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto; }
  .medium-push-3 {
    position: relative;
    left: 25%;
    right: auto; }
  .medium-pull-3 {
    position: relative;
    right: 25%;
    left: auto; }
  .medium-push-4 {
    position: relative;
    left: 33.33333%;
    right: auto; }
  .medium-pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto; }
  .medium-push-5 {
    position: relative;
    left: 41.66667%;
    right: auto; }
  .medium-pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto; }
  .medium-push-6 {
    position: relative;
    left: 50%;
    right: auto; }
  .medium-pull-6 {
    position: relative;
    right: 50%;
    left: auto; }
  .medium-push-7 {
    position: relative;
    left: 58.33333%;
    right: auto; }
  .medium-pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto; }
  .medium-push-8 {
    position: relative;
    left: 66.66667%;
    right: auto; }
  .medium-pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto; }
  .medium-push-9 {
    position: relative;
    left: 75%;
    right: auto; }
  .medium-pull-9 {
    position: relative;
    right: 75%;
    left: auto; }
  .medium-push-10 {
    position: relative;
    left: 83.33333%;
    right: auto; }
  .medium-pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto; }
  .medium-push-11 {
    position: relative;
    left: 91.66667%;
    right: auto; }
  .medium-pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto; }
  .column, .columns {
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
    float: left; }
  .medium-1 {
    width: 8.33333%; }
  .medium-2 {
    width: 16.66667%; }
  .medium-3 {
    width: 25%; }
  .medium-4 {
    width: 33.33333%; }
  .medium-5 {
    width: 41.66667%; }
  .medium-6 {
    width: 50%; }
  .medium-7 {
    width: 58.33333%; }
  .medium-8 {
    width: 66.66667%; }
  .medium-9 {
    width: 75%; }
  .medium-10 {
    width: 83.33333%; }
  .medium-11 {
    width: 91.66667%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-0 {
    margin-left: 0% !important; }
  .medium-offset-1 {
    margin-left: 8.33333% !important; }
  .medium-offset-2 {
    margin-left: 16.66667% !important; }
  .medium-offset-3 {
    margin-left: 25% !important; }
  .medium-offset-4 {
    margin-left: 33.33333% !important; }
  .medium-offset-5 {
    margin-left: 41.66667% !important; }
  .medium-offset-6 {
    margin-left: 50% !important; }
  .medium-offset-7 {
    margin-left: 58.33333% !important; }
  .medium-offset-8 {
    margin-left: 66.66667% !important; }
  .medium-offset-9 {
    margin-left: 75% !important; }
  .medium-offset-10 {
    margin-left: 83.33333% !important; }
  .medium-offset-11 {
    margin-left: 91.66667% !important; }
  .medium-reset-order {
    margin-left: 0;
    margin-right: 0;
    left: auto;
    right: auto;
    float: left; }
  .column.medium-centered, .columns.medium-centered {
    margin-left: auto;
    margin-right: auto;
    float: none; }
  .column.medium-uncentered, .columns.medium-uncentered {
    margin-left: 0;
    margin-right: 0;
    float: left; }
  .column.medium-centered:last-child, .columns.medium-centered:last-child {
    float: none; }
  .column.medium-uncentered:last-child, .columns.medium-uncentered:last-child {
    float: left; }
  .column.medium-uncentered.opposite, .columns.medium-uncentered.opposite {
    float: right; }
  .row.medium-collapse > .column, .row.medium-collapse > .columns {
    padding-left: 0;
    padding-right: 0; }
  .row.medium-collapse .row {
    margin-left: 0;
    margin-right: 0; }
  .row.medium-uncollapse > .column, .row.medium-uncollapse > .columns {
    padding-left: 20px;
    padding-right: 20px;
    float: left; }
  .push-0 {
    position: relative;
    left: 0%;
    right: auto; }
  .pull-0 {
    position: relative;
    right: 0%;
    left: auto; }
  .push-1 {
    position: relative;
    left: 8.33333%;
    right: auto; }
  .pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto; }
  .push-2 {
    position: relative;
    left: 16.66667%;
    right: auto; }
  .pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto; }
  .push-3 {
    position: relative;
    left: 25%;
    right: auto; }
  .pull-3 {
    position: relative;
    right: 25%;
    left: auto; }
  .push-4 {
    position: relative;
    left: 33.33333%;
    right: auto; }
  .pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto; }
  .push-5 {
    position: relative;
    left: 41.66667%;
    right: auto; }
  .pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto; }
  .push-6 {
    position: relative;
    left: 50%;
    right: auto; }
  .pull-6 {
    position: relative;
    right: 50%;
    left: auto; }
  .push-7 {
    position: relative;
    left: 58.33333%;
    right: auto; }
  .pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto; }
  .push-8 {
    position: relative;
    left: 66.66667%;
    right: auto; }
  .pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto; }
  .push-9 {
    position: relative;
    left: 75%;
    right: auto; }
  .pull-9 {
    position: relative;
    right: 75%;
    left: auto; }
  .push-10 {
    position: relative;
    left: 83.33333%;
    right: auto; }
  .pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto; }
  .push-11 {
    position: relative;
    left: 91.66667%;
    right: auto; }
  .pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto; } }

@media only screen and (min-width:95.063em) {
  .large-push-0 {
    position: relative;
    left: 0%;
    right: auto; }
  .large-pull-0 {
    position: relative;
    right: 0%;
    left: auto; }
  .large-push-1 {
    position: relative;
    left: 8.33333%;
    right: auto; }
  .large-pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto; }
  .large-push-2 {
    position: relative;
    left: 16.66667%;
    right: auto; }
  .large-pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto; }
  .large-push-3 {
    position: relative;
    left: 25%;
    right: auto; }
  .large-pull-3 {
    position: relative;
    right: 25%;
    left: auto; }
  .large-push-4 {
    position: relative;
    left: 33.33333%;
    right: auto; }
  .large-pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto; }
  .large-push-5 {
    position: relative;
    left: 41.66667%;
    right: auto; }
  .large-pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto; }
  .large-push-6 {
    position: relative;
    left: 50%;
    right: auto; }
  .large-pull-6 {
    position: relative;
    right: 50%;
    left: auto; }
  .large-push-7 {
    position: relative;
    left: 58.33333%;
    right: auto; }
  .large-pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto; }
  .large-push-8 {
    position: relative;
    left: 66.66667%;
    right: auto; }
  .large-pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto; }
  .large-push-9 {
    position: relative;
    left: 75%;
    right: auto; }
  .large-pull-9 {
    position: relative;
    right: 75%;
    left: auto; }
  .large-push-10 {
    position: relative;
    left: 83.33333%;
    right: auto; }
  .large-pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto; }
  .large-push-11 {
    position: relative;
    left: 91.66667%;
    right: auto; }
  .large-pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto; }
  .column, .columns {
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
    float: left; }
  .large-1 {
    width: 8.33333%; }
  .large-2 {
    width: 16.66667%; }
  .large-3 {
    width: 25%; }
  .large-4 {
    width: 33.33333%; }
  .large-5 {
    width: 41.66667%; }
  .large-6 {
    width: 50%; }
  .large-7 {
    width: 58.33333%; }
  .large-8 {
    width: 66.66667%; }
  .large-9 {
    width: 75%; }
  .large-10 {
    width: 83.33333%; }
  .large-11 {
    width: 91.66667%; }
  .large-12 {
    width: 100%; }
  .large-offset-0 {
    margin-left: 0% !important; }
  .large-offset-1 {
    margin-left: 8.33333% !important; }
  .large-offset-2 {
    margin-left: 16.66667% !important; }
  .large-offset-3 {
    margin-left: 25% !important; }
  .large-offset-4 {
    margin-left: 33.33333% !important; }
  .large-offset-5 {
    margin-left: 41.66667% !important; }
  .large-offset-6 {
    margin-left: 50% !important; }
  .large-offset-7 {
    margin-left: 58.33333% !important; }
  .large-offset-8 {
    margin-left: 66.66667% !important; }
  .large-offset-9 {
    margin-left: 75% !important; }
  .large-offset-10 {
    margin-left: 83.33333% !important; }
  .large-offset-11 {
    margin-left: 91.66667% !important; }
  .large-reset-order {
    margin-left: 0;
    margin-right: 0;
    left: auto;
    right: auto;
    float: left; }
  .column.large-centered, .columns.large-centered {
    margin-left: auto;
    margin-right: auto;
    float: none; }
  .column.large-uncentered, .columns.large-uncentered {
    margin-left: 0;
    margin-right: 0;
    float: left; }
  .column.large-centered:last-child, .columns.large-centered:last-child {
    float: none; }
  .column.large-uncentered:last-child, .columns.large-uncentered:last-child {
    float: left; }
  .column.large-uncentered.opposite, .columns.large-uncentered.opposite {
    float: right; }
  .row.large-collapse > .column, .row.large-collapse > .columns {
    padding-left: 0;
    padding-right: 0; }
  .row.large-collapse .row {
    margin-left: 0;
    margin-right: 0; }
  .row.large-uncollapse > .column, .row.large-uncollapse > .columns {
    padding-left: 20px;
    padding-right: 20px;
    float: left; }
  .push-0 {
    position: relative;
    left: 0%;
    right: auto; }
  .pull-0 {
    position: relative;
    right: 0%;
    left: auto; }
  .push-1 {
    position: relative;
    left: 8.33333%;
    right: auto; }
  .pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto; }
  .push-2 {
    position: relative;
    left: 16.66667%;
    right: auto; }
  .pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto; }
  .push-3 {
    position: relative;
    left: 25%;
    right: auto; }
  .pull-3 {
    position: relative;
    right: 25%;
    left: auto; }
  .push-4 {
    position: relative;
    left: 33.33333%;
    right: auto; }
  .pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto; }
  .push-5 {
    position: relative;
    left: 41.66667%;
    right: auto; }
  .pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto; }
  .push-6 {
    position: relative;
    left: 50%;
    right: auto; }
  .pull-6 {
    position: relative;
    right: 50%;
    left: auto; }
  .push-7 {
    position: relative;
    left: 58.33333%;
    right: auto; }
  .pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto; }
  .push-8 {
    position: relative;
    left: 66.66667%;
    right: auto; }
  .pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto; }
  .push-9 {
    position: relative;
    left: 75%;
    right: auto; }
  .pull-9 {
    position: relative;
    right: 75%;
    left: auto; }
  .push-10 {
    position: relative;
    left: 83.33333%;
    right: auto; }
  .pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto; }
  .push-11 {
    position: relative;
    left: 91.66667%;
    right: auto; }
  .pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto; } }

[class*="block-grid-"] {
  display: block;
  padding: 0;
  margin: 0 0; }
  [class*="block-grid-"]:before, [class*="block-grid-"]:after {
    content: " ";
    display: table; }
  [class*="block-grid-"]:after {
    clear: both; }
  [class*="block-grid-"] > li {
    display: block;
    height: auto;
    float: left;
    padding: 0 0 0; }

@media only screen {
  .small-block-grid-1 > li {
    width: 100%;
    list-style: none; }
    .small-block-grid-1 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-1 > li:nth-of-type(1n+1) {
      clear: both; }
  .small-block-grid-2 > li {
    width: 50%;
    list-style: none; }
    .small-block-grid-2 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-2 > li:nth-of-type(2n+1) {
      clear: both; }
  .small-block-grid-3 > li {
    width: 33.33333%;
    list-style: none; }
    .small-block-grid-3 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-3 > li:nth-of-type(3n+1) {
      clear: both; }
  .small-block-grid-4 > li {
    width: 25%;
    list-style: none; }
    .small-block-grid-4 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-4 > li:nth-of-type(4n+1) {
      clear: both; }
  .small-block-grid-5 > li {
    width: 20%;
    list-style: none; }
    .small-block-grid-5 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-5 > li:nth-of-type(5n+1) {
      clear: both; }
  .small-block-grid-6 > li {
    width: 16.66667%;
    list-style: none; }
    .small-block-grid-6 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-6 > li:nth-of-type(6n+1) {
      clear: both; }
  .small-block-grid-7 > li {
    width: 14.28571%;
    list-style: none; }
    .small-block-grid-7 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-7 > li:nth-of-type(7n+1) {
      clear: both; }
  .small-block-grid-8 > li {
    width: 12.5%;
    list-style: none; }
    .small-block-grid-8 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-8 > li:nth-of-type(8n+1) {
      clear: both; }
  .small-block-grid-9 > li {
    width: 11.11111%;
    list-style: none; }
    .small-block-grid-9 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-9 > li:nth-of-type(9n+1) {
      clear: both; }
  .small-block-grid-10 > li {
    width: 10%;
    list-style: none; }
    .small-block-grid-10 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-10 > li:nth-of-type(10n+1) {
      clear: both; }
  .small-block-grid-11 > li {
    width: 9.09091%;
    list-style: none; }
    .small-block-grid-11 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-11 > li:nth-of-type(11n+1) {
      clear: both; }
  .small-block-grid-12 > li {
    width: 8.33333%;
    list-style: none; }
    .small-block-grid-12 > li:nth-of-type(1n) {
      clear: none; }
    .small-block-grid-12 > li:nth-of-type(12n+1) {
      clear: both; } }

@media only screen and (min-width:46.063em) {
  .medium-block-grid-1 > li {
    width: 100%;
    list-style: none; }
    .medium-block-grid-1 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-1 > li:nth-of-type(1n+1) {
      clear: both; }
  .medium-block-grid-2 > li {
    width: 50%;
    list-style: none; }
    .medium-block-grid-2 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-2 > li:nth-of-type(2n+1) {
      clear: both; }
  .medium-block-grid-3 > li {
    width: 33.33333%;
    list-style: none; }
    .medium-block-grid-3 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-3 > li:nth-of-type(3n+1) {
      clear: both; }
  .medium-block-grid-4 > li {
    width: 25%;
    list-style: none; }
    .medium-block-grid-4 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-4 > li:nth-of-type(4n+1) {
      clear: both; }
  .medium-block-grid-5 > li {
    width: 20%;
    list-style: none; }
    .medium-block-grid-5 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-5 > li:nth-of-type(5n+1) {
      clear: both; }
  .medium-block-grid-6 > li {
    width: 16.66667%;
    list-style: none; }
    .medium-block-grid-6 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-6 > li:nth-of-type(6n+1) {
      clear: both; }
  .medium-block-grid-7 > li {
    width: 14.28571%;
    list-style: none; }
    .medium-block-grid-7 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-7 > li:nth-of-type(7n+1) {
      clear: both; }
  .medium-block-grid-8 > li {
    width: 12.5%;
    list-style: none; }
    .medium-block-grid-8 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-8 > li:nth-of-type(8n+1) {
      clear: both; }
  .medium-block-grid-9 > li {
    width: 11.11111%;
    list-style: none; }
    .medium-block-grid-9 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-9 > li:nth-of-type(9n+1) {
      clear: both; }
  .medium-block-grid-10 > li {
    width: 10%;
    list-style: none; }
    .medium-block-grid-10 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-10 > li:nth-of-type(10n+1) {
      clear: both; }
  .medium-block-grid-11 > li {
    width: 9.09091%;
    list-style: none; }
    .medium-block-grid-11 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-11 > li:nth-of-type(11n+1) {
      clear: both; }
  .medium-block-grid-12 > li {
    width: 8.33333%;
    list-style: none; }
    .medium-block-grid-12 > li:nth-of-type(1n) {
      clear: none; }
    .medium-block-grid-12 > li:nth-of-type(12n+1) {
      clear: both; } }

@media only screen and (min-width:95.063em) {
  .large-block-grid-1 > li {
    width: 100%;
    list-style: none; }
    .large-block-grid-1 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-1 > li:nth-of-type(1n+1) {
      clear: both; }
  .large-block-grid-2 > li {
    width: 50%;
    list-style: none; }
    .large-block-grid-2 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-2 > li:nth-of-type(2n+1) {
      clear: both; }
  .large-block-grid-3 > li {
    width: 33.33333%;
    list-style: none; }
    .large-block-grid-3 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-3 > li:nth-of-type(3n+1) {
      clear: both; }
  .large-block-grid-4 > li {
    width: 25%;
    list-style: none; }
    .large-block-grid-4 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-4 > li:nth-of-type(4n+1) {
      clear: both; }
  .large-block-grid-5 > li {
    width: 20%;
    list-style: none; }
    .large-block-grid-5 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-5 > li:nth-of-type(5n+1) {
      clear: both; }
  .large-block-grid-6 > li {
    width: 16.66667%;
    list-style: none; }
    .large-block-grid-6 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-6 > li:nth-of-type(6n+1) {
      clear: both; }
  .large-block-grid-7 > li {
    width: 14.28571%;
    list-style: none; }
    .large-block-grid-7 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-7 > li:nth-of-type(7n+1) {
      clear: both; }
  .large-block-grid-8 > li {
    width: 12.5%;
    list-style: none; }
    .large-block-grid-8 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-8 > li:nth-of-type(8n+1) {
      clear: both; }
  .large-block-grid-9 > li {
    width: 11.11111%;
    list-style: none; }
    .large-block-grid-9 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-9 > li:nth-of-type(9n+1) {
      clear: both; }
  .large-block-grid-10 > li {
    width: 10%;
    list-style: none; }
    .large-block-grid-10 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-10 > li:nth-of-type(10n+1) {
      clear: both; }
  .large-block-grid-11 > li {
    width: 9.09091%;
    list-style: none; }
    .large-block-grid-11 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-11 > li:nth-of-type(11n+1) {
      clear: both; }
  .large-block-grid-12 > li {
    width: 8.33333%;
    list-style: none; }
    .large-block-grid-12 > li:nth-of-type(1n) {
      clear: none; }
    .large-block-grid-12 > li:nth-of-type(12n+1) {
      clear: both; } }

/* Colors
##################################################################################################################### */
/* Mixins
##################################################################################################################### */
/* Helpers
##################################################################################################################### */
.nospace {
  margin-bottom: 0 !important; }

.halfspace {
  margin-bottom: .5em !important; }

.space-1 {
  margin-bottom: 1em !important; }

.space-2 {
  margin-bottom: 2em !important; }

.space-3 {
  margin-bottom: 3em !important; }

.space-4 {
  margin-bottom: 4em !important; }

.tx-base-color {
  color: #00aeef; }

.tx-gray {
  color: #323232; }

.tx-italic {
  font-style: italic; }

.tx-small {
  font-size: .85rem;
  line-height: 1.25em; }

.icon-pos-left {
  margin-right: .5em;
  margin-left: 0; }

.icon-pos-right {
  margin-right: 0;
  margin-left: .5em; }

.icon-absolute-left {
  position: relative;
  display: inline-block;
  padding-left: 1.5em; }
  .icon-absolute-left span {
    position: absolute;
    top: 0;
    left: -.25em; }

@font-face {
  font-family: 'epartner';
  src: url('../assets/fonts/fontello/epartner.eot?88157679');
  src: url('../assets/fonts/fontello/epartner.eot?88157679#iefix') format('embedded-opentype'), url('../assets/fonts/fontello/epartner.woff?88157679') format('woff'), url('../assets/fonts/fontello/epartner.ttf?88157679') format('truetype'), url('../assets/fonts/fontello/epartner.svg?88157679#epartner') format('svg');
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'epartner';
    src: url('../font/epartner.svg?88157679#epartner') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "epartner";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-cancel:before {
  content: '\e800'; }

/* '' */
.icon-left:before {
  content: '\e801'; }

/* '' */
.icon-right:before {
  content: '\e802'; }

/* '' */
.icon-left-open:before {
  content: '\e803'; }

/* '' */
.icon-right-open:before {
  content: '\e804'; }

/* '' */
/* Typography
##################################################################################################################### */
html, body {
  color: #323232;
  font-family: 'Gotham SSm A', 'Gotham SSm B';
  font-weight: 300;
  font-style: normal;
  font-size: 18px; }

p, ul, ol, address {
  margin: .25em 0 2em;
  line-height: 1.8rem;
  letter-spacing: -.03em; }
  @media only screen and (min-width:95.063em) {
    p, ul, ol, address {
      line-height: 2rem; } }

#lawyerBio p {
  margin-bottom: 0.7rem; }

p.narrow-spacing {
  line-height: 1.3rem; }

a {
  color: inherit;
  text-decoration: underline; }

.fs-bold {
  font-family: 'Gotham SSm A', 'Gotham SSm B';
  font-weight: 700;
  font-style: normal; }

.fs-light {
  font-family: 'Gotham SSm A', 'Gotham SSm B';
  font-weight: 300;
  font-style: normal; }

h1, h2, h3, h4, h5, h6 {
  margin: 1em 0 .3em;
  font-family: 'Gotham SSm A', 'Gotham SSm B';
  font-weight: 700;
  font-style: normal;
  line-height: 1.25em;
  letter-spacing: -.03em; }

h1, .size-1 {
  font-size: 1.66667rem; }

h2, .size-2 {
  font-size: 1.38889rem; }

h3, .size-3 {
  font-size: 1.11111rem; }

h4, .size-4 {
  font-size: 1.11111rem; }

h5, .size-5 {
  font-size: 1.11111rem; }

h6, .size-6 {
  font-size: 1.11111rem; }

@media only screen and (min-width:46.063em) {
  h1, .size-1 {
    font-size: 2.38889rem; }
  h2, .size-2 {
    font-size: 1.38889rem; }
  h3, .size-3 {
    font-size: 1.11111rem; }
  h4, .size-4 {
    font-size: 1.11111rem; }
  h5, .size-5 {
    font-size: 1.11111rem; }
  h6, .size-6 {
    font-size: 1.11111rem; } }

@media only screen and (min-width:95.063em) {
  h1, .size-1 {
    font-size: 3.22222rem; }
  h2, .size-2 {
    font-size: 1.66667rem; }
  h3, .size-3 {
    font-size: 1.11111rem; }
  h4, .size-4 {
    font-size: 1.11111rem; }
  h5, .size-5 {
    font-size: 1.11111rem; }
  h6, .size-6 {
    font-size: 1.11111rem; } }

ul, ol {
  margin: 1em 0;
  padding: 0 0 0 1.4em; }

.stripe-list {
  padding: 0 0 0 1.1em; }
  .stripe-list li {
    position: relative;
    list-style: none; }
    .stripe-list li:before {
      position: absolute;
      top: 24px;
      left: -18px;
      content: '';
      display: block;
      width: 13px;
      height: 1px;
      background: #00aeef; }

.doc-list {
  padding-left: 0; }
  .doc-list li {
    list-style: none;
    margin-bottom: 1rem; }
    .doc-list li span {
      display: block;
      margin-top: .25rem; }

h3.no-margin-top {
  margin-top: 0; }

.no-margin-bottom {
  margin-bottom: 0.5rem; }

h3.corrected-line-height {
  line-height: 1.8rem; }
  @media only screen and (min-width:95.063em) {
    h3.corrected-line-height {
      line-height: 2rem; } }

address {
  font-style: normal; }

img {
  border: 0; }

.publication_text p {
  margin: .25em 0 1em; }

p.blockLink {
  text-align: center;
  margin-top: 1rem; }

.center {
  text-align: center; }

a.button {
  padding: 15px;
  background-color: #00aeef;
  color: white;
  border-radius: 10px;
  text-decoration: none; }

a.button.reverse {
  background-color: white;
  color: #00aeef; }

a.button.big {
  padding: 1rem 1.5rem; }

.epartners-tiny-black {
  background-position: -2px -2px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 200px;
  height: 47px;
  background-image: url("../assets/images/sprite.png"); }
  @media (min--moz-device-pixel-ratio: 1.2), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.2), (min-device-pixel-ratio: 1.2), (min-resolution: 1.2dppx) {
    .epartners-tiny-black {
      background-image: url("../assets/images/sprite@2x.png");
      background-size: 429px 361px; } }

.epartners-small-black {
  background-position: -2px -53px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 250px;
  height: 59px;
  background-image: url("../assets/images/sprite.png"); }
  @media (min--moz-device-pixel-ratio: 1.2), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.2), (min-device-pixel-ratio: 1.2), (min-resolution: 1.2dppx) {
    .epartners-small-black {
      background-image: url("../assets/images/sprite@2x.png");
      background-size: 429px 361px; } }

.epartners-small {
  background-position: -2px -117px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 250px;
  height: 59px;
  background-image: url("../assets/images/sprite.png"); }
  @media (min--moz-device-pixel-ratio: 1.2), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.2), (min-device-pixel-ratio: 1.2), (min-resolution: 1.2dppx) {
    .epartners-small {
      background-image: url("../assets/images/sprite@2x.png");
      background-size: 429px 361px; } }

.epartners-black {
  background-position: -2px -180px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 315px;
  height: 75px;
  background-image: url("../assets/images/sprite.png"); }
  @media (min--moz-device-pixel-ratio: 1.2), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.2), (min-device-pixel-ratio: 1.2), (min-resolution: 1.2dppx) {
    .epartners-black {
      background-image: url("../assets/images/sprite@2x.png");
      background-size: 429px 361px; } }

.epartners {
  background-position: -2px -259px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 425px;
  height: 100px;
  background-image: url("../assets/images/sprite.png"); }
  @media (min--moz-device-pixel-ratio: 1.2), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.2), (min-device-pixel-ratio: 1.2), (min-resolution: 1.2dppx) {
    .epartners {
      background-image: url("../assets/images/sprite@2x.png");
      background-size: 429px 361px; } }

/* Layout
##################################################################################################################### */
html, body {
  -webkit-text-size-adjust: 100%; }

.section-inner {
  padding: 2rem 0;
  background: white; }
  @media only screen and (min-width:46.063em) {
    .section-inner {
      padding: 4rem 0; } }

.detail-content {
  padding: 4% 0 10%; }

.detail-content-right {
  padding-bottom: 5%; }
  @media only screen and (min-width:46.063em) {
    .detail-content-right {
      padding-top: 5%;
      padding-right: 5%; } }

.publication-team-einheit {
  float: left;
  margin-right: 40px;
  margin-bottom: 0.4rem; }
  @media only screen and (min-width:46.063em) {
    .publication-team-einheit {
      margin-bottom: 0; } }
  .publication-team-einheit img {
    width: 400px;
    height: auto; }

.pdf-dl {
  padding: 10px 0;
  text-align: center;
  margin: 0; }
  @media only screen and (min-width:46.063em) {
    .pdf-dl {
      margin: 20px 0;
      text-align: left; } }

#sectors .block, #services .block {
  margin: 0 0.1rem 1rem 0.1rem;
  padding: 1rem;
  height: 100%;
  border-radius: 25px; }

#sectors .block {
  background-color: #00aeef;
  color: white; }

#services .block {
  background-color: #FFF;
  border: 5px solid #00aeef; }

/* Orientation bar
##################################################################################################################### */
.orientation-bar {
  position: fixed;
  display: none;
  z-index: 50;
  top: 0;
  right: 0;
  left: 0;
  background: #00aeef;
  -webkit-transition: all .75s ease-in-out;
  -moz-transition: all .75s ease-in-out;
  transition: all .75s ease-in-out; }
  .orientation-bar::after {
    clear: both;
    content: "";
    display: table; }
  .orientation-bar.fixed {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: 0; }
    @media screen and (min-width: 950px) {
      .orientation-bar.fixed .logo-black {
        display: block;
        opacity: 1; } }
  .orientation-bar.open {
    display: block; }
  .orientation-bar .logo-black {
    position: absolute;
    display: block;
    opacity: 0;
    -webkit-transition: all .75s ease-in-out;
    -moz-transition: all .75s ease-in-out;
    transition: all .75s ease-in-out; }
    @media screen and (min-width: 950px) {
      .orientation-bar .logo-black {
        top: 18px;
        background-position: -2px -2px;
        background-repeat: no-repeat;
        overflow: hidden;
        display: block;
        width: 200px;
        height: 47px;
        background-image: url("../assets/images/sprite.png");
        display: none; }
        @media (min--moz-device-pixel-ratio: 1.2), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.2), (min-device-pixel-ratio: 1.2), (min-resolution: 1.2dppx) {
          .orientation-bar .logo-black {
            background-image: url("../assets/images/sprite@2x.png");
            background-size: 429px 361px; } } }
    @media only screen and (min-width:95.063em) {
      .orientation-bar .logo-black {
        top: 30px;
        background-position: -2px -180px;
        background-repeat: no-repeat;
        overflow: hidden;
        display: block;
        width: 315px;
        height: 75px;
        background-image: url("../assets/images/sprite.png");
        display: block;
        opacity: 0; }
        @media (min--moz-device-pixel-ratio: 1.2), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.2), (min-device-pixel-ratio: 1.2), (min-resolution: 1.2dppx) {
          .orientation-bar .logo-black {
            background-image: url("../assets/images/sprite@2x.png");
            background-size: 429px 361px; } } }
  @media only screen and (min-width:46.063em) {
    .orientation-bar {
      position: relative;
      display: block; } }

#bar-trigger {
  position: fixed;
  z-index: 100;
  top: 20px;
  right: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer; }
  #bar-trigger .burger {
    width: 35px; }
    #bar-trigger .burger span {
      display: block;
      height: 6px;
      margin-bottom: 4px;
      background: #00aeef; }
      #bar-trigger .burger span:last-child {
        margin-bottom: 0; }
  #bar-trigger .icon-cancel {
    display: none;
    color: white;
    font-size: 2rem;
    line-height: .8rem; }
    #bar-trigger .icon-cancel:before {
      margin: 0;
      line-height: 1.5rem; }
  #bar-trigger.open .burger {
    display: none; }
  #bar-trigger.open .icon-cancel {
    display: block; }
  @media only screen and (min-width:46.063em) {
    #bar-trigger {
      display: none; } }

.main-nav {
  margin: 0;
  padding: 20px 0; }
  .main-nav ul {
    margin: 0;
    padding: 0 40px 0 0;
    list-style: none; }
    .main-nav ul li {
      vertical-align: top;
      border: 1px solid transparent; }
      .main-nav ul li a {
        position: relative;
        display: block;
        padding: .25rem;
        text-decoration: none;
        vertical-align: top; }
      .main-nav ul li.active a {
        color: white; }
        .main-nav ul li.active a:before {
          content: '';
          display: block;
          position: absolute;
          bottom: 7px;
          left: -10px;
          margin-left: -10px;
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-left: 10px solid white; }
      .main-nav ul li.lang {
        display: inline-block;
        vertical-align: top; }
        .main-nav ul li.lang a {
          display: inline-block;
          vertical-align: top; }
        .main-nav ul li.lang.lang-active {
          color: white; }
  @media only screen and (min-width:46.063em) {
    .main-nav {
      float: right;
      padding: 0;
      padding-top: 1.11111rem; }
      .main-nav ul {
        padding: 0; }
        .main-nav ul li {
          display: inline-block;
          -webkit-transition: all .05s ease-in-out;
          -moz-transition: all .05s ease-in-out;
          transition: all .05s ease-in-out; }
          .main-nav ul li a {
            padding: .75em .5rem;
            -webkit-transition: all .05s ease-in-out;
            -moz-transition: all .05s ease-in-out;
            transition: all .05s ease-in-out; }
          .main-nav ul li.active a:before {
            content: '';
            display: block;
            position: absolute;
            bottom: -1px;
            left: 50%;
            margin-left: -10px;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid white; }
          .main-nav ul li.lang a {
            padding: .75em .25rem; }
          .main-nav ul li.lang.lang-start {
            margin-left: 3rem; } }
  @media only screen and (min-width:95.063em) {
    .main-nav {
      padding-top: 3.88889rem; }
      .main-nav ul li a {
        padding: .75em 1rem; } }

/* Parallax areas
##################################################################################################################### */
.intro-parallax {
  min-height: 500px;
  background: transparent;
  overflow: hidden; }
  @media only screen and (min-width:46.063em) {
    .intro-parallax {
      min-height: 550px; } }

.banner {
  background-image: url(/assets/images/opacity_small.png);
  background-repeat: repeat;
  background-position: 0px 0px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }
  @media only screen and (min-width:46.063em) {
    .banner {
      background-image: url(/assets/images/opacity.png);
      background-repeat: repeat;
      background-position: 0px 0px;
      top: 35%;
      height: 340px; }
      @media only screen and (min-height: 800px) {
        .banner {
          top: 45%; } }
      @media only screen and (min-height: 1000px) {
        .banner {
          top: 50%; } } }
  @media only screen and (min-width:95.063em) and (min-height: 800px) {
    .banner {
      top: 45%;
      left: 0;
      width: 100%;
      height: 360px; } }
  @media only screen and (min-width:105.063em) and (min-height: 1400px) {
    .banner {
      top: 45%;
      left: 0;
      width: 100%;
      height: 420px; } }
  @media only screen and (min-width:46.063em) {
    .banner.english {
      height: 340px; } }
  @media only screen and (min-width:95.063em) {
    .banner.english {
      top: 40%;
      left: 0;
      width: 100%;
      height: 450px; } }
  @media only screen and (min-width:105.063em) {
    .banner.english {
      top: 40%;
      left: 0;
      width: 100%;
      height: 450px; } }
  .banner .logo {
    position: absolute;
    top: 20px;
    left: 20px;
    background-position: -2px -117px;
    background-repeat: no-repeat;
    overflow: hidden;
    display: block;
    width: 250px;
    height: 59px;
    background-image: url("../assets/images/sprite.png"); }
    @media (min--moz-device-pixel-ratio: 1.2), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.2), (min-device-pixel-ratio: 1.2), (min-resolution: 1.2dppx) {
      .banner .logo {
        background-image: url("../assets/images/sprite@2x.png");
        background-size: 429px 361px; } }
    @media screen and (max-width: 320px) {
      .banner .logo {
        left: 0;
        -webkit-transform: scale(.8);
        -moz-transform: scale(.8);
        -ms-transform: scale(.8);
        -o-transform: scale(.8);
        transform: scale(.8); } }
    @media only screen and (min-width:46.063em) {
      .banner .logo {
        top: 40px;
        left: 20px;
        background-position: -2px -259px;
        background-repeat: no-repeat;
        overflow: hidden;
        display: block;
        width: 425px;
        height: 100px;
        background-image: url("../assets/images/sprite.png"); }
        @media (min--moz-device-pixel-ratio: 1.2), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.2), (min-device-pixel-ratio: 1.2), (min-resolution: 1.2dppx) {
          .banner .logo {
            background-image: url("../assets/images/sprite@2x.png");
            background-size: 429px 361px; } } }
    @media only screen and (min-width:105.063em) {
      .banner .logo {
        top: 0px;
        left: 50%;
        margin-left: -683px;
        margin-top: 30px; } }
  .banner h2 {
    position: absolute;
    left: 0;
    margin: 0;
    top: 120px;
    padding: 0 20px 0 43px;
    font-weight: 500; }
    @media screen and (max-width: 320px) {
      .banner h2 {
        font-size: 1.38889rem; } }
    @media only screen and (min-width:46.063em) {
      .banner h2 {
        top: 170px;
        padding: 0 20px; } }
    @media only screen and (min-width:105.063em) {
      .banner h2 {
        max-width: 1350px;
        left: 50%;
        margin-left: -675px;
        top: 170px;
        margin-bottom: 40px;
        padding: 0 25px; } }

.section-parallax {
  min-height: 200px;
  background: transparent; }
  @media only screen and (min-width:46.063em) {
    .section-parallax {
      min-height: 400px; } }
  @media only screen and (min-width:105.063em) {
    .section-parallax {
      min-height: 550px; } }

.home-slider {
  z-index: 0; }
  .home-slider .img-init {
    display: none; }
  .home-slider .cycle-slide {
    width: inherit;
    height: inherit;
    display: block; }

/* Subbar
##################################################################################################################### */
.subbar {
  position: fixed;
  z-index: 50;
  top: 0;
  right: 0;
  left: 0;
  height: 45px;
  background: #00aeef; }
  .subbar + div {
    margin-top: 45px; }
  .subbar a {
    display: inline-block;
    color: white;
    text-decoration: none;
    padding: .45rem; }
  .subbar .lang-subbar {
    vertical-align: bottom;
    float: right;
    color: black;
    padding-left: 0rem;
    margin: 0; }
  .subbar .lang-subbar-active {
    color: white; }
  @media only screen and (min-width:46.063em) {
    .subbar {
      height: 60px; }
      .subbar + div {
        margin-top: 60px; }
      .subbar a {
        padding: .85rem; } }

/* Fixed image
##################################################################################################################### */
.fixed-image {
  height: 400px;
  margin-left: -20px;
  margin-right: -20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center; }
  @media only screen and (min-width:46.063em) {
    .fixed-image {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      width: 45%;
      height: 100%;
      margin-left: 0;
      margin-right: 0; } }

/* Team
##################################################################################################################### */
.team-list a {
  text-decoration: none; }
  .team-list a h2 {
    text-decoration: underline; }
@media only screen and (min-width:46.063em) {
  .team-list p {
    padding: 0;
    margin: 0; } }
.team-list p.narrow-spacing {
  margin-bottom: 0;
  padding-bottom: 0; }
@media only screen and (min-width:46.063em) {
  .team-list li {
    padding: 20px;
    padding-bottom: 0; } }
@media only screen and (min-width:105.063em) {
  .team-list {
    margin-left: -40px;
    margin-right: -40px; }
    .team-list li {
      padding: 40px;
      padding-bottom: 0; } }

/* Map
##################################################################################################################### */
.map-container {
  position: relative; }
  .map-container .map-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

#map-canvas {
  position: relative;
  height: 250px; }
  #map-canvas img {
    max-width: none; }
  @media only screen and (min-width:46.063em) {
    #map-canvas {
      height: 450px; } }
  @media only screen and (min-width:95.063em) {
    #map-canvas {
      height: 550px; } }
  @media only screen and (min-width:105.063em) {
    #map-canvas {
      height: 650px; } }

/* Archiv
##################################################################################################################### */
.filter {
  margin: 3rem 0;
  padding: 1.5rem 0 1.25rem;
  border-top: 1px solid #00aeef;
  border-bottom: 1px solid #00aeef; }
  .filter h2, .filter h3 {
    margin: 0 0 .75rem; }
  .filter .tag {
    display: inline-block;
    margin: 0 .25rem .5rem 0;
    padding: .2rem .5rem;
    text-decoration: none;
    -webkit-transition: all .12s ease-in-out;
    -moz-transition: all .12s ease-in-out;
    transition: all .12s ease-in-out; }
    .filter .tag .icon-cancel {
      display: none;
      margin-left: .25rem;
      font-size: .7rem; }
      .filter .tag .icon-cancel:before {
        position: relative;
        top: -2px;
        margin: 0 0 0 2px;
        padding: 0; }
    .filter .tag:hover {
      background: #f0f0f0; }
    .filter .tag.active {
      color: white;
      background: #00aeef; }
      .filter .tag.active .icon-cancel {
        display: inline-block; }
      .filter .tag.active:hover {
        background: #009bd5; }

/* Footer
##################################################################################################################### */
footer {
  padding: 40px 0 0;
  border-bottom: 10px solid #00aeef;
  background: white; }

.true-footer {
  background: #00aeef;
  padding: 0.5em 1em 0 1em;
  text-align: right;
  font-family: 'Gotham SSm A', 'Gotham SSm B';
  font-weight: 300;
  font-style: normal;
  font-size: 18px;
  line-height: normal; }

.true-footer a {
  text-decoration: none;
  color: black; }

