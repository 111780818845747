/* Orientation bar
##################################################################################################################### */

// Orientation bar
.orientation-bar {
    position: fixed;
    display: none;
    z-index: 50;
    top: 0;
    right: 0;
    left: 0;
    @include clearfix;
    background: $ep-base-color;
    @include transition(all .75s ease-in-out);

    &.fixed {
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;

        .logo-black {
            @include breakpoint-min(950px) {
                display: block;
                opacity: 1;
            }
        }
    }

    &.open {
        display: block;
    }

    .logo-black {
        position: absolute;
        display: block;
        opacity: 0;
        @include transition(all .75s ease-in-out);

        @include breakpoint-min(950px) {
            top: 18px;
            @include sprite($epartners-tiny-black);
            display: none;
        }

        @media #{$large-up} {
            top: 30px;
            @include sprite($epartners-black);
            display: block;
            opacity: 0;
        }
    }

    @media #{$medium-up} {
        position: relative;
        display: block;
    }
}

// Bar trigger
#bar-trigger {
    position: fixed;
    z-index: 100;
    top: $column-gutter/2;
    right: $column-gutter/2;
    @include user-select(none);
    cursor: pointer;

    .burger {
        width: 35px;

        span {
            display: block;
            height: 6px;
            margin-bottom: 4px;
            background: $ep-base-color;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .icon-cancel {
        display: none;
        color: $white;
        font-size: 2rem;
        line-height: .8rem;

        &:before {
            margin: 0;
            line-height: 1.5rem;
        }
    }

    &.open {
        .burger {
            display: none;
        }

        .icon-cancel {
            display: block;
        }
    }

    @media #{$medium-up} {
        display: none;
    }
}

// Main navigation
.main-nav {
    margin: 0;
    padding: 20px 0;

    ul {
        margin: 0;
        padding: 0 40px 0 0;
        list-style: none;

        li {
            vertical-align: top;
            border: 1px solid transparent;

            a {
                position: relative;
                display: block;
                padding: .25rem;
                text-decoration: none;
                vertical-align: top;
            }

            &.active {
                a {
                    color: $white;

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: 7px;
                        left: -10px;
                        margin-left: -10px;
                        width: 0;
                        height: 0;
                        border-top: 10px solid transparent;
                        border-bottom: 10px solid transparent;
                        border-left: 10px solid $white;
                    }
                }
            }

            &.lang {
                display: inline-block;
                vertical-align: top;

                a {
                    display: inline-block;
                    vertical-align: top;
                }

                &.lang-active {
                    color: $white;
                }
            }
        }
    }

    @media #{$medium-up} {
        float: right;
        padding: 0;
        padding-top: rem(20px);

        ul {
            padding: 0;

            li {
                display: inline-block;
                @include transition(all .05s ease-in-out);

                a {
                    padding: .75em .5rem;
                    @include transition(all .05s ease-in-out);
                }

                &.active {

                    a {
                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            bottom: -1px;
                            left: 50%;
                            margin-left: -10px;
                            width: 0;
                            height: 0;
                            border-left: 10px solid transparent;
                            border-right: 10px solid transparent;
                            border-bottom: 10px solid $white;
                        }
                    }
                }

                &.lang {

                    a {
                        padding: .75em .25rem;
                    }

                    &.lang-start {
                        margin-left: 3rem;
                    }
                }
            }
        }
    }

    @media #{$large-up} {
        padding-top: rem(70px);

        ul li a {
            padding: .75em 1rem;
        }
    }

}
