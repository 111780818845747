/* Map
##################################################################################################################### */

.map-container {
    position: relative;

    .map-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

#map-canvas {
    position: relative;
    height: 250px;

    img {
        max-width: none;
    }

    @media #{$medium-up} {
        height: 450px;
    }

    @media #{$large-up} {
        height: 550px;
    }

    @media #{$xlarge-up} {
        height: 650px;
    }
}