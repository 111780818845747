/* Fixed image
##################################################################################################################### */

.fixed-image {
    height: 400px;
    margin-left: -$column-gutter/2;
    margin-right: -$column-gutter/2;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;

    @media #{$medium-up} {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        width: 45%;
        height: 100%;
        margin-left: 0;
        margin-right: 0;
    }
}