/* Footer
##################################################################################################################### */

footer {
    padding: $column-gutter 0 0;
    border-bottom: 10px solid $ep-base-color;
    background: $white;
}

.true-footer {
    background: $ep-base-color;
    padding: 0.5em 1em 0 1em;
    text-align: right;
    font-family: 'Gotham SSm A', 'Gotham SSm B';
    font-weight: 300;
    font-style: normal;
    font-size: $em-base;
    line-height: normal;
}

.true-footer a {
    text-decoration: none;
    color: black;
}