/* Typography
##################################################################################################################### */

$em-base: 18px;

$headersize-h1: rem(58px);
$headersize-h2: rem(30px);
$headersize-h3: rem(20px);
$headersize-h4: rem(20px);
$headersize-h5: rem(20px);
$headersize-h6: rem(20px);

$h1-small-reduction: rem(28px);
$h2-small-reduction: rem(5px);
$h3-small-reduction: rem(0);
$h4-small-reduction: rem(0);
$h5-small-reduction: rem(0);
$h6-small-reduction: rem(0);

$h1-medium-reduction: rem(15px);
$h2-medium-reduction: rem(5px);
$h3-medium-reduction: rem(0);
$h4-medium-reduction: rem(0);
$h5-medium-reduction: rem(0);
$h6-medium-reduction: rem(0);

// Global
html,
body {
    color: $gray-50;
    font-family: 'Gotham SSm A', 'Gotham SSm B';
    font-weight: 300;
    font-style: normal;
    font-size: $em-base;
}

p,
ul,
ol,
address {
    margin: .25em 0 2em;
    line-height: 1.8rem;
    letter-spacing: -.03em;

    @media #{$large-up} {
        line-height: 2rem;
    }
}

#lawyerBio p {
    margin-bottom: 0.7rem;
}

p.narrow-spacing {
    line-height: 1.3rem;
}

// Anchor
a {
    color: inherit;
    text-decoration: underline;
}

// Font weights
.fs-bold {
    font-family: 'Gotham SSm A', 'Gotham SSm B';
    font-weight: 700;
    font-style: normal;
}

.fs-light {
    font-family: 'Gotham SSm A', 'Gotham SSm B';
    font-weight: 300;
    font-style: normal;
}

// Headlines
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 1em 0 .3em;
    font-family: 'Gotham SSm A', 'Gotham SSm B';
    font-weight: 700;
    font-style: normal;
    line-height: 1.25em;
    letter-spacing: -.03em;
}

h1,
.size-1 {
    font-size: $headersize-h1 - $h1-small-reduction;
}

h2,
.size-2 {
    font-size: $headersize-h2 - $h2-small-reduction;
}

h3,
.size-3 {
    font-size: $headersize-h3 -$h3-small-reduction;
}

h4,
.size-4 {
    font-size: $headersize-h4 - $h4-small-reduction;
}

h5,
.size-5 {
    font-size: $headersize-h5 - $h5-small-reduction;
}

h6,
.size-6 {
    font-size: $headersize-h6 - $h6-small-reduction;
}

// Medium up
@media #{$medium-up} {

    h1,
    .size-1 {
        font-size: $headersize-h1 - $h1-medium-reduction;
    }

    h2,
    .size-2 {
        font-size: $headersize-h2 - $h2-medium-reduction;
    }

    h3,
    .size-3 {
        font-size: $headersize-h3 -$h3-medium-reduction;
    }

    h4,
    .size-4 {
        font-size: $headersize-h4 - $h4-medium-reduction;
    }

    h5,
    .size-5 {
        font-size: $headersize-h5 - $h5-medium-reduction;
    }

    h6,
    .size-6 {
        font-size: $headersize-h6 - $h6-medium-reduction;
    }

}

// Large up
@media #{$large-up} {

    h1,
    .size-1 {
        font-size: $headersize-h1;
    }

    h2,
    .size-2 {
        font-size: $headersize-h2;
    }

    h3,
    .size-3 {
        font-size: $headersize-h3;
    }

    h4,
    .size-4 {
        font-size: $headersize-h4;
    }

    h5,
    .size-5 {
        font-size: $headersize-h5;
    }

    h6,
    .size-6 {
        font-size: $headersize-h6;
    }

}

// Lists
ul,
ol {
    margin: 1em 0;
    padding: 0 0 0 1.4em;
}

.stripe-list {
    padding: 0 0 0 1.1em;

    li {
        position: relative;
        list-style: none;

        &:before {
            position: absolute;
            top: 24px;
            left: -18px;
            content: '';
            display: block;
            width: 13px;
            height: 1px;
            background: $ep-base-color;
        }
    }
}

.doc-list {
    padding-left: 0;

    li {
        list-style: none;
        margin-bottom: 1rem;
		
        span {
            display: block;
            margin-top: .25rem;
        }
    }
}

h3.no-margin-top {
    margin-top: 0;
}

.no-margin-bottom {
    margin-bottom: 0.5rem;
}

h3.corrected-line-height {
    line-height: 1.8rem;
    
    @media #{$large-up} {
       line-height: 2rem;
    }
}


// Address
address {
    font-style: normal;
}

// Images
img {
    border: 0;
}

.publication_text p {
    margin: .25em 0 1em;
}

p.blockLink {
    text-align: center; 
    margin-top: 1rem;
}

.center {
    text-align: center;
}

a.button {

    padding: 15px;
    background-color: $ep-base-color;
    color: white;
    border-radius: 10px;
    text-decoration: none;

}

a.button.reverse {
    background-color: white;
    color: $ep-base-color;
}

a.button.big {
    padding: 1rem 1.5rem;
}