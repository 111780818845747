/* Layout
##################################################################################################################### */

html,
body {
    -webkit-text-size-adjust: 100%;
}

.section-inner {
    padding: 2rem 0;
    background: $white;

    @media #{$medium-up} {
        padding: 4rem 0;
    }
}

.detail-content {
    padding: 4% 0 10%;
}

.detail-content-right {
    padding-bottom: 5%;

    @media #{$medium-up} {
        padding-top: 5%;
        padding-right: 5%;
    }
}

.publication-team-einheit {
    float: left;
    margin-right: $column-gutter;

    margin-bottom: 0.4rem;

    @media #{$medium-up} {
      margin-bottom: 0;
    }

    img {
        width: 400px;
        height: auto;
    }

    
    
}

.pdf-dl {
    padding: 10px 0; 
    text-align: center;
    margin: 0;

    @media #{$medium-up} {
        margin: 20px 0;
        text-align: left;
    }
    
}

#sectors .block, #services .block {
    margin: 0 0.1rem 1rem 0.1rem; 
    padding: 1rem; 
    height: 100%; 
    border-radius: 25px; 
} 

#sectors .block {
    background-color: #00aeef; 
    color: white
}

#services .block {
    background-color: #FFF; 
    border: 5px solid #00aeef
}

