/* Base
##################################################################################################################### */

// Foundation overwrites
$row-width: 1350px;
$total-columns: 12;
$column-gutter: 40px;

$small-range: (0em, 46em);
$medium-range: (46.063em, 95em);
$large-range: (95.063em, 105em);
$xlarge-range: (105.063em, 120em);
$xxlarge-range: (120.063em, 99999999em);

$block-grid-default-spacing: 0;

// Frameworks & Libraries
@import '../../bower_components/foundation/scss/foundation/components/grid';
@import '../../bower_components/foundation/scss/foundation/components/block-grid';
@import '../../bower_components/bourbon/app/assets/stylesheets/bourbon';

// Project imports
@import 'general/colors';
@import 'general/mixins';
@import 'general/helpers';
@import 'general/icons';
@import 'general/type';
@import 'general/sprite';
@import 'general/layout';
//@import 'general/dev';

@import 'modules/orientation-bar';
@import 'modules/parallax-areas';
@import 'modules/home-slider';
@import 'modules/subbar';
@import 'modules/fixed-image';
@import 'modules/team';
@import 'modules/map';
@import 'modules/archiv';
@import 'modules/footer';