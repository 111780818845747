/* Parallax areas
##################################################################################################################### */

.intro-parallax {
    min-height: 500px;
    background: transparent;
    overflow: hidden;

	

    @media #{$medium-up} {
        min-height: 550px;
    }

}

.banner {
	background-image: url(/assets/images/opacity_small.png);
	background-repeat: repeat;
	background-position: 0px 0px;

	position: absolute;
    top: 0;
    left: 0;
	
	height: 100%;
	width: 100%;
	
	
	@media #{$medium-up} {
		background-image: url(/assets/images/opacity.png);
		background-repeat: repeat;
		background-position: 0px 0px;
		top: 35%;
		@media only screen and (min-height: 800px) {
			top: 45%;
		}
		
		@media only screen and (min-height: 1000px) {
			top: 50%;
		}
		
		height: 340px;
	}
	
	@media #{$large-up} and (min-height:800px) {
	        top: 45%;
			left: 0;
			width: 100%;
			height: 360px;
	}
	
    @media #{$xlarge-up} and (min-height:1400px) {
        top: 45%;
		left: 0;
		width: 100%;
		height: 420px;
    }
	
	&.english {
		@media #{$medium-up} {
			height: 340px;
		}
	
		@media #{$large-up} {
		        top: 40%;
				left: 0;
				width: 100%;
				height: 450px;
		}
	
	
	    @media #{$xlarge-up} {
	        top: 40%;
			left: 0;
			width: 100%;
			height: 450px;
	    }
	}

    .logo {
        
		position: absolute;
        top: $column-gutter/2;
        left: $column-gutter/2;
		
        @include sprite($epartners-small);

        @include breakpoint-max(320px) {
            left: 0;
            @include transform(scale(.8));
        }

        @media #{$medium-up} {
            top: ($column-gutter); 
            left: $column-gutter/2;
            @include sprite($epartners);
        }

        @media #{$xlarge-up} {
            top: 0px;
            left: 50%;
            margin-left: (-$row-width/2)+(-8px);
			margin-top: 30px;
        }
    }

    h2 {
       	position: absolute;
        left: 0;
        margin: 0;
        top: $column-gutter*3;
		padding: 0 $column-gutter/2 0 43px;
		
		
		font-weight: 500;
		
        @include breakpoint-max(320px) {
            font-size: rem(25px);
        }

        @media #{$medium-up} {
            top: 170px;
            padding: 0 $column-gutter/2;
			//margin-left: -$row-width/2;			
        }
		
	    @media #{$xlarge-up} {
	        max-width: $row-width;
	        left: 50%;
	        margin-left: -$row-width/2;
	        top: 170px;
			margin-bottom: 40px;
            padding: 0 25px;
			
	    }

    }

}

.section-parallax {
    min-height: 200px;
    background: transparent;

    @media #{$medium-up} {
        min-height: 400px;
    }

    @media #{$xlarge-up} {
        min-height: 550px;
    }
}