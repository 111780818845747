/* Team
##################################################################################################################### */

.team-list {

    a {
        text-decoration: none;

        h2 {
            text-decoration: underline;
        }
    }
	
	p {
                		
	    @media #{$medium-up} {
			padding: 0;
			margin: 0;
	    }
    }
    
    p.narrow-spacing {
            margin-bottom: 0;
            padding-bottom: 0;
    }

    @media #{$medium-up} {
        li {
            padding: $column-gutter/2;
			padding-bottom: 0;
        }
    }

    @media #{$xlarge-up} {
        margin-left: -$column-gutter;
        margin-right: -$column-gutter;

        li {
            padding: $column-gutter;
			padding-bottom: 0;
        }
    }
}