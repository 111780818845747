@font-face {
    font-family: 'epartner';
    src: url('../assets/fonts/fontello/epartner.eot?88157679');
    src: url('../assets/fonts/fontello/epartner.eot?88157679#iefix') format('embedded-opentype'),
    url('../assets/fonts/fontello/epartner.woff?88157679') format('woff'),
    url('../assets/fonts/fontello/epartner.ttf?88157679') format('truetype'),
    url('../assets/fonts/fontello/epartner.svg?88157679#epartner') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'epartner';
    src: url('../font/epartner.svg?88157679#epartner') format('svg');
  }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "epartner";
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-cancel:before {
    content: '\e800';
}

/* '' */
.icon-left:before {
    content: '\e801';
}

/* '' */
.icon-right:before {
    content: '\e802';
}

/* '' */
.icon-left-open:before {
    content: '\e803';
}

/* '' */
.icon-right-open:before {
    content: '\e804';
}

/* '' */