/* Mixins
##################################################################################################################### */

// Mixin: Custom breakpoint
@mixin breakpoint-min($point) {
    @media screen and (min-width: $point) {
        @content;
    }
}

@mixin breakpoint-max($point) {
    @media screen and (max-width: $point) {
        @content;
    }
}