/* Subbar
##################################################################################################################### */

$subbar-small-height: 45px;
$subbar-large-height: 60px;

.subbar {
    position: fixed;
    z-index: 50;
    top: 0;
    right: 0;
    left: 0;
    height: $subbar-small-height;
    background: $ep-base-color;

    + div {
        margin-top: $subbar-small-height;
    }

    a {
        display: inline-block;
        color: $white;
        text-decoration: none;
        padding: .45rem;
    }

    .lang-subbar {
	  vertical-align: bottom;
	  float: right;
      color: $black;
	  padding-left: 0rem;
	  margin: 0;
    }

    .lang-subbar-active {
      color: $white;
    }

    @media #{$medium-up} {
        height: $subbar-large-height;

        + div {
            margin-top: $subbar-large-height;
        }

        a {
            padding: .85rem;
        }
    }
}